export function extractShopifyTemperature(metafieldsMap) {
    const temperatureC = metafieldsMap['custom.temperature_range_c'];
    const temperatureF = metafieldsMap['custom.temperature_range_f'];

    if (temperatureC && temperatureF) {
        const [lowC, highC] = temperatureC.split('-');
        const [lowF, highF] = temperatureF.split('-');
        return {
            temperature_low_in_c: lowC,
            temperature_high_in_c: highC,
            temperature_low_in_f: lowF,
            temperature_high_in_f: highF,
        };
    }

    return null;
}
