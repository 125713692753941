import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';
import setIdsToken from '@app/lib/profile/set-ids-token';

export default async function storeWishlist(
    idstToken: string,
    skus: string[]
): Promise<any> {
    const now = Date.now();
    const route = '/api/wishlist/store';
    const body = {
        skus,
        idst: idstToken,
    };
    const signature = await hmacSignature(
        process.env.NEXT_PUBLIC_API_TOKEN,
        now,
        route,
        body
    );
    return fetch(route, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-authentication': `${now}:${signature}`,
        },
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.wishlist) {
                return true;
            }
            if (response.errorDetails?.clearToken) {
                setIdsToken(null);
            }
            console.log('storeWishlist error', response);
            return false;
        })
        .catch((error) => {
            console.log('storeWishlist error', error);
            return false;
        });
}
