import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';

const mapItem = (item) => {
    return {
        sku: item.product_variant.sku,
        handle: item.product.handle,
    };
};

export default async function fetchWishlist(idstToken: string): Promise<any> {
    const now = Date.now();
    const route = '/api/wishlist/fetch';
    const body = {
        idst: idstToken,
    };
    const signature = await hmacSignature(
        process.env.NEXT_PUBLIC_API_TOKEN,
        now,
        route,
        body
    );
    return fetch(route, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-authentication': `${now}:${signature}`,
        },
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.wishlist) {
                return {
                    items: response.wishlist.items.map(mapItem),
                };
            }
            return null;
        })
        .catch((error) => {
            console.log('error fetching wishlist', error);
        });
}
