export const GALLERY_ORDER = [
    'PDP_01',
    'VIDEO',
    'PDP_02',
    'PDP_03',
    'PDP_04',
    'PDP_05',
    'PDP_06',
    'PDP_07',
    'PDP_08',
    'PDP_09',
    'PDP_FLATLAY',
];

export const NEW_TO_OLD_NAMING_MAP = {
    PDP_01: 'HERO_01',
    PDP_02: 'ADDITIONAL_IMAGES_01',
    PDP_03: 'SPECS_01',
    PDP_04: 'ADDITIONAL_IMAGES_02',
    PDP_05: 'SPECS_02',
    PDP_FLATLAY: 'SPECS_03_DESKTOP',
    // PLP_Flatlay: 'LP_FLATLAY',
};
