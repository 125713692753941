// Originally I tried using the first collection handle as a filter but sometimes
// that did not pick up all products belonging to a parent sku.
// We need the handle to be as general as possible in order to detect the
// maximum number of 'siblings'.

export function getAppropriateCollectionHandle(product) {
    const prioritizedCollections = ['all-man', 'all-woman', 'accessories'];
    let selectedCollectionHandle = null;

    for (const edge of product.collections.edges) {
        const collectionHandle = edge.node.handle;
        if (prioritizedCollections.includes(collectionHandle)) {
            selectedCollectionHandle = collectionHandle;
            break;
        }
    }

    if (!selectedCollectionHandle && product.collections.edges.length > 0) {
        selectedCollectionHandle = product.collections.edges[0].node.handle;
    }

    return selectedCollectionHandle;
}
