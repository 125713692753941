export const ICON_MAPPING = {
    Antibacterial: 'anti-bacterial',
    Breathable: 'breathable',
    'Moisture Wicking': 'moisture-wicking',
    '4 Way Stretch': 'four-way-stretch',
    'DWR Coating': 'dwr-coating',
    Packable: 'packable',
    'Reflective Graphics': 'reflective-graphics',
    Stowable: 'stowable',
    Lightweight: 'lightweight',
    'Ultra-Lightweight': 'ultra-lightweight',
    Waterproof: 'waterproof',
    Windproof: 'windproof',
    'Taped Seams': 'taped-seams',
    'Down Fill': 'down-fill',
    'Touchscreen Conductive': 'touchscreen-conductive',
    'Italian Fabric': 'italian-fabric',
    'Quick Drying': 'quick-drying',
    'Thermal Regulation': 'thermal-regulation',
    'UPF 50+ Protection': 'spf-50-protection',
    'Anti-Odour': 'anti-odour',
    'Recycled Yarns': 'recycled-yarns',
    'MAAP Honeycomb': 'maap-honeycomb',
    'bluesign® APPROVED Fabric': 'bluesign',
    'Compression Fabrics': 'compression',
    'Dri-Release Fabric': 'dri-release-fabric',
    Insulated: 'insulated',
    'Organic Cotton': 'organic-cotton',
};
