import { normaliseDetailName } from './normalise-detail-name';

export function extractProductDetails(
    product,
    productDetailLabels
): string[] | undefined {
    // If details are available from metafields, return those and exit.
    // No need to normalise these as they should be entered correctly
    if (productDetailLabels && productDetailLabels.length > 0) {
        return productDetailLabels;
    }

    // If no details available from metafields, get them from legacy tags and from prismic
    let details: string[] = [];
    // Details from Legacy tags, if they exist
    if (product && product.tags && product.tags.length > 0) {
        const detailsFromTags = product.tags
            .filter((tag) => tag.match(/feature:/i))
            .map((tag) => tag.replace(/^feature:\d*:/, '').trim())
            .map(normaliseDetailName)
            .filter((detail) => detail);

        details.push(...detailsFromTags);
    }

    // Remove any duplicates
    return [...new Set(details)];
}
