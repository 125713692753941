import {
    extractDetailLabels,
    extractFeatureDescriptions,
    extractProductGender,
    extractShopifyTemperature,
} from '../utilities';

// TODO: type this object

export function processProductMetafields(product) {
    if (!product || !product.metafields) return {};

    const metafieldsMap = product.metafields.reduce((acc, field) => {
        if (field && field.namespace && field.key) {
            acc[`${field.namespace}.${field.key}`] = field.value;
        }
        return acc;
    }, {});

    return {
        gender: extractProductGender(metafieldsMap),
        shortDescription:
            metafieldsMap['product.short_description'] || product.description,
        longDescription:
            metafieldsMap['custom.long_form_pdp_description'] ||
            'Description temporarily unavailable.',
        fallbackProductFeatures: metafieldsMap['product.long_description'],
        detailLabels: extractDetailLabels(metafieldsMap),
        featureDescriptions: extractFeatureDescriptions(metafieldsMap),
        backgroundColor: metafieldsMap['custom.feature_block_hex_code'],
        componentTheme:
            metafieldsMap[
                'custom.light_text_or_dark_text_linked_to_hex_code_value_field'
            ],
        shopifyTemperature: extractShopifyTemperature(metafieldsMap),
        shopifyCareInstructions: metafieldsMap['custom.care_instructions'],
        shopifyRidingConditions: metafieldsMap['custom.riding_conditions'],
        shopifyProductWeight: metafieldsMap['custom.product_weight'],
        shopifyFabricContent: metafieldsMap['custom.material_composition'],
        shopifyProductSizing: metafieldsMap['product.sizing'],
        parentSKU: product.parentSKU?.value,
        prismicDocumentUIDS:
            metafieldsMap['custom.prismic_document_uids']?.split(','),
        mediaGalleryVideo: metafieldsMap['custom.pdp_media_gallery_video'],
        featureVideo: metafieldsMap['custom.pdp_feature_component_video'],
        featureVideoLocation:
            metafieldsMap['custom.pdp_feature_component_video_location'],
        fit: metafieldsMap['custom.maap_fit'],
    };
}
