import { SwiperItem } from '@app/components/swiper/type';
import { GALLERY_ORDER, NEW_TO_OLD_NAMING_MAP } from '../constants';

export function createGalleryItems(
    images: any[],
    videos: any[] | undefined
): SwiperItem[] {
    return GALLERY_ORDER.reduce((acc: SwiperItem[], pattern) => {
        const findMatchingItem = (items: any[], pattern: string) =>
            items.find((item) => item.src.includes(pattern));

        const matchingImage =
            findMatchingItem(images, pattern) ||
            findMatchingItem(images, NEW_TO_OLD_NAMING_MAP[pattern]);

        if (matchingImage) {
            acc.push({
                productImage: [
                    {
                        desktopImage: matchingImage.src,
                        mobileImage: matchingImage.src,
                        altText: matchingImage.alt,
                        height: matchingImage.height,
                        width: matchingImage.width,
                        link: null,
                    },
                ],
            });
        }

        if (videos?.length && pattern === 'VIDEO') {
            const findMatchingVideo = (pattern: string) =>
                videos.find((video) => video.type.includes(pattern));

            const matchingVideo =
                findMatchingVideo(pattern) || findMatchingVideo('VIDEO');

            if (matchingVideo) {
                const highestQualitySource = matchingVideo.videoSources[0];
                if (highestQualitySource) {
                    acc.push({
                        poster: matchingVideo?.src,
                        productVideo: [
                            {
                                desktopVideoUrl: highestQualitySource.url,
                                mobileVideoUrl: highestQualitySource.url,
                                controls: false,
                                autoplay: true,
                                muted: true,
                                loop: true,
                            },
                        ],
                    });
                }
            }
        }

        return acc;
    }, [] as SwiperItem[]);
}
