export function extractProductGender(metafieldsMap) {
    let gender = metafieldsMap['custom.gender_multiselect'];
    if (gender) {
        try {
            const genderArray = JSON.parse(gender);

            if (genderArray.includes('Unisex')) {
                return 'Unisex';
            }

            return Array.isArray(genderArray) ? genderArray[0] : null;
        } catch (error) {
            console.error('Error parsing gender:', error);
            return null;
        }
    }
    return null;
}
