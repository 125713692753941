import { create } from 'zustand';
import { ContentStructureNavItem } from '@app/lib/content-structure';
import { createRef } from 'react';

type NavTheme = 'light' | 'dark';

type OpenUIKey =
    | 'cart'
    | 'wishlist'
    | 'search-panel'
    | 'support-menu'
    | 'main-menu-mobile'
    | 'main-mobile-menu'
    | 'product-assistance'
    | 'collection-filters'
    | 'language-switch'
    | 'profile'
    | 'password-reset';

export type ProductAssistanceView = string | null;

interface UIState {
    openUIKey: null | string;
    navTheme: NavTheme;
    activeNavItem: null | ContentStructureNavItem;
    setActiveNavItem: (item: ContentStructureNavItem) => void;
    toggleUI: (key: string | null) => void;
    transitionUI: (
        fromKey: string | null,
        toKey: string | null
    ) => Promise<void>;
    toggleNavTheme: () => void;
    setNavTheme: (theme: NavTheme) => void;
    isIntersecting: boolean;
    setIntersection: (intersecting: boolean) => void;
    productAssistanceView: ProductAssistanceView;
    setProductAssistanceView: (view: ProductAssistanceView) => void;
    isWishlistItemSaved: boolean;
    toggleWishlistItem: () => void;
    productHeroRef: React.RefObject<Element>;
    setProductHeroRef: (ref: React.RefObject<Element>) => void;
}

const useUI = create<UIState>((set, get) => ({
    openUIKey: null,
    navTheme: 'light',
    activeNavItem: null,
    setActiveNavItem: (item) => set(() => ({ activeNavItem: item })),
    toggleUI: (key) =>
        set((state) =>
            state.openUIKey === key ? { openUIKey: null } : { openUIKey: key }
        ),
    toggleNavTheme: () =>
        set((state) => ({
            navTheme: state.navTheme === 'light' ? 'dark' : 'light',
        })),
    setNavTheme: (theme) => set(() => ({ navTheme: theme })),
    isIntersecting: false,
    setIntersection: (intersecting) =>
        set(() => ({ isIntersecting: intersecting })),
    productAssistanceView: null,
    setProductAssistanceView: (view) =>
        set(() => ({ productAssistanceView: view })),
    isWishlistItemSaved: false,
    toggleWishlistItem: () =>
        set((state) => ({ isWishlistItemSaved: !state.isWishlistItemSaved })),
    productHeroRef: createRef(),
    setProductHeroRef: (ref) => set(() => ({ productHeroRef: ref })),
    transitionUI: async (fromKey: string | null, toKey: string | null) => {
        const currentUIKey = get().openUIKey;
        if (currentUIKey === fromKey) {
            set({ openUIKey: null });
            setTimeout(() => {
                set({ openUIKey: toKey });
            }, 300);
            // If the closing of the old and opening of the new
            // doesn't feel right, adjust the 300ms value
        } else {
            set((state) => ({
                openUIKey: state.openUIKey === toKey ? null : toKey,
            }));
        }
    },
}));

export default useUI;
