import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { WishlistItem } from '@app/lib/wishlist/types';
import getIdsToken from '@app/lib/profile/get-ids-token';
import fetchWishlist from './fetch-wishlist';
import storeWishlist from '@app/lib/wishlist/store-wishlist';

export interface WishlistState {
    items: WishlistItem[];
    itemCount: number;
    isSaved: boolean;
    isMerged: boolean;
    isSaving: boolean;
    isStoreFailed: boolean;
    toggleItem: (item: WishlistItem) => void;
    addItem: (item: WishlistItem) => void;
    switchItem: (originalItemSKU: string, newItem: WishlistItem) => void;
    removeItem: (sku: string) => void;
    isSKUSaved: (sku: string) => boolean;
    isProductSaved: (handle: string) => boolean;
    load: () => void;
    save: () => void;
    merge: () => void;
    clear: () => void;
}

export const useWishlist = create<WishlistState>()(
    persist(
        (set, get) => ({
            wishlist: null,
            items: [],
            itemCount: 0,
            isSaved: false,
            isMerged: false,
            isSaving: false,
            error: true,
            isStoreFailed: false,

            addItem: (item: WishlistItem) => {
                set((state) => {
                    const items = state.items || [];
                    return {
                        items: [...items, item],
                        itemCount: items.length + 1,
                        isSaved: false,
                    };
                });
                get().save();
            },

            removeItem: (sku: string) => {
                set((state) => {
                    const newItems = state.items.filter(
                        (wishlistItem) => wishlistItem.sku !== sku
                    );
                    return {
                        items: newItems,
                        itemCount: newItems.length,
                        isSaved: false,
                    };
                });
                get().save();
            },

            switchItem: (originalItemSKU: string, newItem: WishlistItem) => {
                const updatedItems = [...get().items];
                const originalItemIndex = updatedItems.findIndex(
                    (wishlistItem) => wishlistItem.sku === originalItemSKU
                );
                updatedItems[originalItemIndex] = newItem;
                set(() => {
                    return {
                        items: updatedItems,
                        itemCount: updatedItems.length,
                        isSaved: false,
                    };
                });
            },

            isProductSaved: (handle: string) => {
                const items = get().items;
                if (!items || !items.length) return false;

                const itemIndex = items.findIndex(
                    (wishlistItem) => wishlistItem.handle === handle
                );

                return itemIndex > -1;
            },

            isSKUSaved: (sku: string) => {
                const items = get().items;
                if (!items || !items.length) return false;
                const itemIndex = items.findIndex(
                    (wishlistItem) => wishlistItem.sku === sku
                );
                return itemIndex > -1;
            },

            toggleItem: (item: WishlistItem) => {
                if (!get().isSKUSaved(item.sku)) {
                    return get().addItem(item);
                }
                return get().removeItem(item.sku);
            },

            load: async () => {
                const idst = getIdsToken();
                if (!idst) return;
                const wishlist = await fetchWishlist(idst);
                if (!wishlist) return;
                set(() => ({
                    items: wishlist.items,
                    itemCount: wishlist.items.length,
                    isSaved: true,
                }));
            },

            save: async () => {
                const idst = getIdsToken();
                if (!idst) {
                    // console.log('No token');
                    return;
                }
                const skus = get().items.map((item) => item.sku);
                set(() => ({
                    isSaving: true,
                }));
                const storeResult = await storeWishlist(idst, skus);
                if (!storeResult.error) {
                }
                set(() => ({
                    isSaved: storeResult,
                    isStoreFailed: !storeResult,
                    isSaving: false,
                }));
            },

            merge: async () => {
                const idst = getIdsToken();
                if (!idst) return;
                set(() => ({
                    isSaving: true,
                }));
                const localSkus = get().items.map((item) => item.sku);
                const storedWishlist = await fetchWishlist(idst);
                if (!storedWishlist) return;
                const storedWishlistSkus = storedWishlist.items.map(
                    (item) => item.sku
                );

                // Array of both the remote, and local SKUs with duplicates removed.
                const mergedSkus = [
                    ...localSkus,
                    ...storedWishlistSkus.filter(
                        (sku) => !localSkus.includes(sku)
                    ),
                ].filter((item, index, self) => {
                    return index === self.indexOf(item);
                });

                const storeResult = await storeWishlist(idst, mergedSkus);

                set(() => ({
                    isMerged: storeResult,
                    isStoreFailed: !storeResult,
                    isSaving: false,
                }));
                get().load();
            },

            clear: () => {
                set(() => ({
                    items: [],
                    itemCount: 0,
                    isSaved: false,
                }));
                get().save();
            },
        }),
        {
            name: 'wishlist:cache',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({
                items: state.items,
                itemCount: state.itemCount,
                isSaved: state.isSaved,
            }),
        }
    )
);

export default useWishlist;
