const specialCases = {
    'anti-odour': 'Anti-Odour',
    'dri-release-fabric': 'Dri-Release Fabric',
    'bluesign-approved-fabric': 'bluesign® APPROVED Fabric',
    'maap-honeycomb-mesh-sleeves': 'MAAP Honeycomb',
    'ultra-lightweight': 'Ultra-Lightweight',
    'anti-bacterial': 'Antibacterial',
};

export function normaliseDetailName(input: string): string {
    const cleanInput = input.toLowerCase().trim();

    const directMatch = Object.keys(specialCases).find(
        (key) => key === cleanInput
    );

    if (directMatch) {
        return specialCases[directMatch];
    }

    // General case: Remove hyphens and apply title case, unless specified in special cases
    return input
        .split(/-/)
        .join(' ')
        .split(' ')
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
}
