import { Context, Language } from '../store-context/Context';
import { getContext } from '@app/lib/store-context/get-context';
import { ShopifyClientContext } from '@app/lib/shopify/client';

export function getShopifyClientSettings(
    countryCode: string
): ShopifyClientContext {
    const context: Context = getContext(countryCode as Language);

    return {
        shopifyDomain: context.shopifyDomain,
        storefrontAccessToken: context.shopifyStorefrontToken,
        languageCode: context.language,
        countryCode: context.countryCode,
        shopifyMarketCountryCode: context.shopifyMarketCountryCode,
    };
}
